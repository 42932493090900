<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">客户中心</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学校列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="学校名称" class="searchboxItem ci-full">
              <span class="itemLabel">学校名称:</span>
              <el-input v-model="searchForm.schoolName" clearable size="small" placeholder="请输入学校名称"></el-input>
            </div>
            <div title="所在地市" class="searchboxItem ci-full">
              <span class="itemLabel">所在地市:</span>
              <el-cascader clearable filterable v-model="searchForm.areaId" :options="areatreeList" :props="propsarea"
                size="small"></el-cascader>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="addEdit()">新增</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader">
              <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod" width="100px" />
              <el-table-column label="学校名称" align="center" show-overflow-tooltip prop="schoolName" minWidth="180" />
              <el-table-column label="所在地市" align="center" show-overflow-tooltip prop="areaName" minWidth="150" />
              <el-table-column label="办学性质" align="center" show-overflow-tooltip prop="schoolNature">
                <template slot-scope="scope">
                  {{ $setDictionary("INTERN_SCHOOL_NATURE", scope.row.schoolNature) }}
                </template>
              </el-table-column>
              <el-table-column label="院校分类" align="center" show-overflow-tooltip prop="schoolClassify">
                <template slot-scope="scope">
                  {{ $setDictionary("INTERN_SCHOOL_CLASSIFY", scope.row.schoolClassify) }}
                </template>
              </el-table-column>
              <el-table-column label="联系人" align="center" show-overflow-tooltip prop="schoolPerson" minWidth="180" />
              <el-table-column label="创建日期" align="center" show-overflow-tooltip prop="createTime" minWidth="180" />
              <el-table-column label="是否在小程序展示" align="center" show-overflow-tooltip width="180">
                <template slot-scope="scope">
                  <el-switch :width="20" v-model="scope.row.appletShowState" :active-value="true" 
                    :inactive-value="false" @change="setAppletShowState(scope.row);"></el-switch>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" show-overflow-tooltip minWidth="180">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="addEdit(scope.row.schoolId)">编辑</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="delSchool(scope.row.schoolId)">删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "internship_customerCenter_schoolList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      searchForm: {
        schoolName: '',
        areaId: '',
      },
      // 区划数据
      areatreeList: [],
      // 区划字段控制
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },

    };
  },
  created() {
    this.getareatree()
  },
  mounted() {
  },
  methods: {
    // 获取区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    // 获取列表
    getData(pageNum = 1) {
      const { schoolName, areaId } = this.searchForm
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        schoolName: schoolName || undefined,
        areaId: areaId || undefined,
      };
      this.doFetch({
        url: "/biz/intern/school/page",
        params,
        pageNum
      });
    },
    // 是否在小程序显示
    setAppletShowState(row){
      this.$post("/biz/intern/school/appletShowState/modify",{
        schoolId: row.schoolId,
        appletShowState: !row.appletShowState
      }).then((res) => {
        this.$message.success("操作成功");
        this.getData(this.pageNum);
      });
    },
    // 编辑
    addEdit(schoolId) {
      this.$router.push({
        path: "/web/internship/customerCenter/schoolDetail",
        query: {
          schoolId,
        }
      });
    },
    // 删除
    delSchool(schoolId) {
      this.doDel({
        url: "/biz/intern/school/delete",
        msg: "你确定要删除该学校吗？",
        ps: {
          type: "post",
          data: { schoolId },
        },
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例
      if (from.path == '/web/internship/customerCenter/schoolDetail') {
        vm.getData()
      }
    })
  },
  watch: {

  }
};
</script>
<style lang="less" scoped>
.searchBox {
  padding: 0 !important;

  .el-icon-arrow-down:before {
    content: '\e6df' !important;
  }
}
</style>
